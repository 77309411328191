import { request } from '@/util/request'

export function doAdd(data) {
  return request({
    url: '/api/CaseCopy/Add',
    method: 'post',
    data,
  })
}

export function getList(data, params) {
  return request({
    url: '/api/CaseCopy/GetList?strOrderByFields=' + params.strOrderByFields,
    method: 'post',
    data,
  })
}

export function getCaseCopyInfo(params) {
  return request({
    url: '/api/CaseCopy/GetCaseCopyInfo',
    method: 'get',
    params,
  })
}

export function payCaseCopy(params) {
  return request({
    url: '/api/CaseCopy/PayCaseCopy',
    method: 'get',
    params,
  })
}

export function getCopyFee(params) {
  return request({
    url: '/api/CaseCopy/GetCopyFee',
    method: 'get',
    params,
  })
}
