<template>
  <div>
    <div class="card">
      <div class="title">
        住院信息
      </div>
      <div class="list">
        <div class="grid-table">
          <div>医院</div>
          <div>余姚市妇幼保健院</div>
          <div>患者姓名</div>
          <div>{{ formData.name }}</div>
          <div>身份证号码</div>
          <div>{{ formData.cardNo }}</div>
          <div>住院号</div>
          <div>{{ formData.hospitalNumber }}</div>
          <div>入院时间</div>
          <div>{{ formData.ryTime }}</div>
          <div>出院时间</div>
          <div>{{ formData.cyTime }}</div>
          <div>住院科室</div>
          <div>{{ formData.deptName }}</div>
          <div>手机号</div>
          <div>{{ formData.mobile }}</div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="title">
        证件照片
      </div>
      <div class="list">
        <van-row class="picList" style="text-align: center">
          <van-col v-for="(item, i) in formData.imageList" :key="i" :span="24">
            <div v-if="item.des != ''">
              <van-row>
                <div
                  style="padding: 10px;background: white;box-sizing: content-box"
                >
                  <van-image
                    v-for="(url, j) in item.url"
                    :key="j"
                    width="100%"
                    height="150"
                    fit="contain"
                    :src="url"
                  />
                </div>
              </van-row>
              <van-row>
                {{ item.des }}
              </van-row>
            </div>
            <div v-else style="height: 216px">&nbsp;</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="card">
      <div class="title">
        复印和快递信息
      </div>
      <div class="list">
        <div class="grid-table">
          <div>收货地址</div>
          <div>{{ formData.address }}&nbsp;{{ formData.addressInfo }}</div>
          <div>复印份数</div>
          <div>{{ formData.copyCount }}份</div>
          <div>复印内容</div>
          <div>{{ formData.copyContentText }}</div>
          <div>费用</div>
          <div>{{ formData.copyFee }}元</div>
          <div>审批状态</div>
          <div>{{ formData.statusText }}</div>
          <div>审批意见</div>
          <div>{{ formData.reamrk }}</div>
          <div>支付状态</div>
          <div>{{ formData.payStateText }}</div>
          <div>支付时间</div>
          <div>{{ formData.payTime }}</div>
          <div>快递名称</div>
          <div>{{ formData.expressName }}</div>
          <div>快递单号</div>
          <div>{{ formData.expressNumber }}</div>
        </div>
      </div>
    </div>
    <van-button
      v-if="formData.payState === 0 && formData.status === 1"
      type="info"
      round
      @click="pay"
    >
      <div>支付</div>
    </van-button>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { getCaseCopyInfo, payCaseCopy } from '@/api/case/caseCopy'
  import { getJSAPIConfig } from '@/api/wechat/wechat'
  import { Toast } from 'vant'

  export default {
    name: 'CaseCopyInfo',
    data() {
      return {
        id: null,
        loading: false,
        formData: {},
      }
    },
    created() {
      this.id = Number(Base64.decode(this.$route.query.id))
      this.fetchData()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '加载中...',
          forbidClick: true,
        })
        const { data } = await getCaseCopyInfo({ ID: this.id })
        this.formData = data
        this.formData.address = this.formData.address
          .replace('|', '')
          .replace('|', '')
        Toast.clear()
      },
      async onBridgeReady() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '调起支付...',
          forbidClick: true,
        })
        let that = this
        const { data } = await payCaseCopy({
          ID: this.id,
        })
        Toast.clear()
        await window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: data.appId, //公众号ID，由商户传入
            timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: data.nonceStr, //随机串
            package: data.package,
            signType: 'RSA', //微信签名方式：
            paySign: data.paySign,
          },
          function(res) {
            if (res.err_msg == 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              that.fetchData()
            }
          }
        )
      },

      pay() {
        if (typeof WeixinJSBridge == 'undefined') {
          if (document.addEventListener) {
            document.addEventListener(
              'WeixinJSBridgeReady',
              function() {
                this.onBridgeReady()
              },
              false
            )
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
          }
        } else {
          this.onBridgeReady()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-button--info {
    width: 95%;
    margin: 2.5%;
  }
  .card {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    font-family: 'Microsoft Yahei';
    .des {
      text-align: center;
    }
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
  .picList {
    display: grid;
    grid-template-columns: 50% 50%;
    .van-col {
    }
  }
</style>
