import { request } from '@/util/request'

export function getOpenID(params) {
  return request({
    url: '/api/WeChat/GetOpenid',
    method: 'GET',
    params,
  })
}

export function getWXConfigInfo(params) {
  return request({
    url: '/api/Wechat/GetJSPower',
    method: 'GET',
    params,
  })
}

export function getJSAPIConfig(params) {
  return request({
    url: '/api/Wechat/GetJSAPIConfig',
    method: 'GET',
    params,
  })
}
